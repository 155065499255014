import request from '@/utils/request'
import qs from 'qs'

export function getDtoById(id) {
  return request({
    url: 'api/jxj/yxxs/' + id,
    method: 'get'
  })
}

export function add(data) {
  return request({
    url: 'api/jxj/yxxs',
    method: 'post',
    data
  })
}

export function del(ids) {
  return request({
    url: 'api/jxj/yxxs',
    method: 'delete',
     data: ids
  })
}

export function edit(data) {
  return request({
    url: 'api/jxj/yxxs',
    method: 'put',
    data
  })
}

export function download(params) {
  return request({
    url: 'api/jxj/yxxs/download' + '?' + qs.stringify(params, { indices: false }),
    method: 'get',
    responseType: 'blob'
  })
}

export function downloadPdf(id) {
  return request({
    url: 'api/jxj/yxxs/pdf/' + id,
    method: 'get',
    responseType: 'blob'
  })
}


export default { del, edit, add, getDtoById, download,downloadPdf }
